<template>
  <v-app style="background: #121212">
    <v-system-bar height="30" dark>
      <v-icon small>mdi-qrcode-scan</v-icon>
      <span v-if="!session?.Party?.name">Carregando...</span>
      <template v-else>
        {{ session?.Party.name }} • {{ session.name }} •
        {{ session.scannerName }}
      </template>
      <v-spacer></v-spacer>
      <span>{{ currentTime | date("HH:mm") }}</span>
    </v-system-bar>
    <v-theme-provider dark>
      <div
        v-if="loading || error"
        class="text-center d-flex flex-column justify-center align-center"
        style="position: fixed; top: 30px; left: 0; right: 0; bottom: 0"
      >
        <template v-if="!error">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <h6 class="mt-4">Carregando...</h6>
        </template>
        <template v-else>
          <div class="d-flex flex-column px-4 w-full justify-center">
            <v-alert text color="error" class="mb-6">
              {{ error }}
            </v-alert>
            <div>
              <v-btn @click="loadScannerSession" color="primary">
                Tentar novamente
              </v-btn>
              <br />
              <v-btn
                @click="disconnect"
                class="mt-4"
                :loading="loading"
                text
                small
              >
                Desconectar <v-icon right>mdi-logout</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <v-btn
          fab
          fixed
          style="top: 45px; left: 15px"
          @click="guideline"
          color="primary"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <Scanner
          :paused="paused"
          :disallowMethods="['nfc']"
          @scan="onScan"
          loading
          style="position: fixed; top: 30px; left: 0; right: 0; bottom: 0"
        />
        <div
          class="d-flex flex-column"
          style="position: fixed; bottom: 25px; left: 20px; right: 20px"
        >
          <v-card v-if="lastResult" class="mb-2 rounded-lg mx-2">
            <v-alert
              text
              dense
              class="mb-0"
              :icon="lastResult.approved ? 'mdi-check' : 'mdi-close'"
              :type="lastResult.approved ? 'success' : 'error'"
            >
              <div>
                <p
                  class="text-overline lh-1 mb-0"
                  style="font-size: 10px !important"
                >
                  Ultima validação {{ [lastResult.time] | dateDiff(true) }}
                </p>
                <p
                  v-if="lastResult.ticket?.Owner"
                  class="mb-0 font-weight-bold"
                >
                  {{ lastResult.ticket?.Owner.name | shortName }}
                </p>
                <p v-else class="mb-0 font-weight-bold">
                  Usuário não cadastrado
                </p>
                <p class="mb-0 lh-1">
                  {{ lastResult.ticket?.TicketBlock.TicketGroup.name }} •
                  {{ lastResult.ticket?.TicketBlock.name }}
                </p>
              </div>
            </v-alert>
          </v-card>
          <v-card class="d-flex align-center gap-3 rounded-lg">
            <div>
              <v-img
                class="rounded-l-lg"
                :src="session?.Party?.cover"
                height="70"
                :width="(70 * 16) / 9"
                :aspect-ratio="16 / 9"
              ></v-img>
            </div>
            <div class="flex-grow-1 flex-shrink-1 py-1">
              <div style="display: grid">
                <h6 class="mb-0 text-truncate">
                  {{ session?.Party.name || "Carregando..." }}
                </h6>
                <div class="d-flex gap-1 align-center gap-x-2">
                  <template v-if="currentPeriod">
                    <div class="d-flex flex-column">
                      <span
                        class="font-weight-medium"
                        style="line-height: 1rem"
                      >
                        {{ currentPeriod.name }}
                      </span>

                      <span class="flex-grow-0 lh-1 flex-shrink-1">
                        {{
                          currentPeriod | startEndDate("startDate", "endDate")
                        }}
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="flex-shrink-0 pr-4">
              <v-btn @click="findByCpf" color="primary" small fab elevation="0">
                <v-icon>mdi-account-search</v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
        <scanner-guideline :session="session" :period="currentPeriod" />

        <scanner-entry
          :session="session"
          @finish="finish"
          @close="disabled = false"
        />
        <ScannerFindByCPF :session="session" @scan="onScan" />
      </div>
    </v-theme-provider>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Scanner from "../../../components/global/scanner/Scanner.vue";
import SCANNER from "@/services/staff/scanner";
import ScannerGuideline from "../../../components/staff/scanner/ScannerGuideline.vue";
import ScannerEntry from "../../../components/staff/scanner/ScannerEntryModal.vue";
import ScannerFindByCPF from "../../../components/staff/scanner/ScannerFindByCPF.vue";

export default {
  name: "ScannerInit",
  metaInfo: {
    title: "Scanner",
  },
  data: () => ({
    error: null,
    loading: true,
    disabled: false,
    paused: false,

    sessionId: null,
    session: null,

    lastResult: null,

    interval: null,
    currentTime: new Date(),
  }),
  components: {
    Scanner,
    ScannerGuideline,
    ScannerEntry,
    ScannerFindByCPF,
  },
  methods: {
    onScan(data) {
      this.paused = true;
      this.$nextTick(() => {
        this.paused = false;
      });

      if (this.disabled) return;

      const { value } = data;
      this.disabled = true;

      this.$root.$emit("close:guideline", value);
      this.$root.$emit("entry-confirm", value);
    },
    finish(result) {
      if (result) this.lastResult = { ...result, time: new Date() };
      this.disabled = false;
    },
    async loadScannerSession() {
      try {
        this.loading = true;
        this.error = null;

        const rawSession = atob(this.$route.params.session);
        const sessionBody = atob(rawSession.split(".")[1]);
        const parsedSession = JSON.parse(sessionBody);
        this.sessionId = parsedSession.id;

        var name = this.getSessionName(parsedSession);

        const data = await SCANNER.getSession(this.sessionId, rawSession, name);

        this.session = data.session;
        this.saveToStore(this.$route.params.session, data.session);
        this.$nextTick(() => {
          this.$root.$emit("guideline", 4000);
        });
      } catch (error) {
        console.error(error);
        this.error = error.message || "Erro ao carregar a sessão";
      } finally {
        this.loading = false;
      }
    },
    getSessionName(session) {
      if (session.name) return undefined;

      const savedSession = localStorage.getItem("staff:scanner");
      if (savedSession) {
        const sessionData = JSON.parse(savedSession);
        if (session.id === sessionData.id) return sessionData.scannerName;
      }

      return window.prompt("Digite o nome do scanner", this.user?.name);
    },
    saveToStore(token, session) {
      const body = {
        ...session,
        token,
        savedAt: new Date(),
      };
      localStorage.setItem("staff:scanner", JSON.stringify(body));
    },
    disconnect() {
      localStorage.removeItem("staff:scanner");
      this.$router.push({ name: "staff.scanner" });
    },
    guideline() {
      this.$root.$emit("guideline");
    },
    findByCpf() {
      this.$root.$emit("find-by-cpf");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    currentPeriod() {
      var hasPeriods =
        this.session?.Party?.Period && this.session?.Party.Period.length > 0;
      if (!hasPeriods) return null;
      var currentPeriod = this.session?.Party.Period.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      })
        .map((p, i) => ({ ...p, name: p.name || `Periodo ${i + 1}` }))
        .find((period) => {
          return new Date(period.endDate) > new Date();
        });

      return {
        ...currentPeriod,
        inner:
          !!currentPeriod && new Date(currentPeriod.startDate) < new Date(),
      };
    },
  },

  mounted() {
    this.loadScannerSession();
    this.interval = setInterval(() => {
      this.currentTime = new Date();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
</style>