import http from "@/http-common";

const getSession = async (id, token, name) => {
  return await http
    .get(`/staff/scanner/session/${id}`, {
      params: { name: name },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getTicket = async (id, token, code) => {
  const codeBase64 = btoa(code);


  return await http
    .get(`/staff/scanner/session/${id}/ticket/${codeBase64}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const useTicket = async (id, token, code, approved) => {
  const codeBase64 = btoa(code);

  return await http
    .post(
      `/staff/scanner/session/${id}/ticket/${codeBase64}`,
      { approved },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const findByCPF = async (id, token, value, type = 'document') => {
  return await http
    .get(`/staff/scanner/session/${id}/ticket/cpf/${value}?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getSession,
  getTicket,
  useTicket,
  findByCPF,
};
